//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';

export default {
  name: 'MemberPasswordDialog',
  props: ["show_status", "mcode"],
  data () {
    return {
      isPwd: true,
      loading: false,
      fixed: false,
      old_password: "",
      new_password: "",
      confirm_password: ""
    }
  },
  methods: {
     validatePassword: function () {
       //alert("before password validate");
       if (this.new_password != "" && this.old_password != "") {
          /*
          alert("this.confirm_password:" + this.confirm_password);
          alert("this.new_password:" + this.new_password);
          alert("this.old_password:" + this.old_password);
          */
          if (this.confirm_password == this.new_password && this.old_password != this.new_password) {
              //alert("valid password");
              this.$q.dialog({
                  title: "ยืนยันการเปลี่ยนรหัสผ่าน",
                  message: "ทำการบันทึกรหัสผ่านใหม่" ,
                  persistent: true,
                  html: true,
                  class: "app-font",
                  style: "background-color:#FFFFFF; color:#000000;",
                  ok: {
                    label: "ตกลง",
                    color: 'positive'
                  },
                  cancel: {
                    label: "ยกเลิก"
                  },
                }).onOk(() => {
                  this.updateMemberPassword();
                  // console.log('>>>> OK')
                }).onOk(() => {
                  // console.log('>>>> second OK catcher')
                }).onCancel(() => {
                  // console.log('>>>> Cancel')
                }).onDismiss(() => {
                  // console.log('I am triggered on both OK and Cancel')
              });
          } else {
            //alert("error");
            if (this.old_password == this.new_password) {
                this.triggerWarning("รหัสผ่านใหม่ ซ้ำกับ รหัสผ่านเดิม");
            } else {
                if (this.confirm_password != this.new_password) {
                    this.triggerWarning("รหัสผ่านใหม่ ไม่ตรงกับ ยืนยันรหัสผ่าน");
                }
            }
          }
       }
     },
     updateMemberPassword: function () {
      //alert("post update password");
      let self = this;
      self.loading = true;
      const params = new URLSearchParams();
      params.append('mcode', this.mcode);
      params.append('pwd_old', this.old_password);
      params.append('pwd_new', this.new_password);

      self.axios({
        method: 'post',
        headers: {
          'Authorization': self.jwt_token
        },        
        timeout: 10000,
        url: self.url_api + 'MemberClient/MemberChangePassword.JSON.aspx',
        data: params
      })
      .then(function (response) {
        let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
        console.log("result :" + responseDoc.getElementsByTagName('results')[0].textContent);
        self.loading = false;
        self.triggerPositive();
      })
      .catch(function (error) {
          console.log("Error :" + error);
          self.loading = false;
          self.triggerNegative();
      });
    }, 
    triggerCustomRegisteredType2 () {
      // this one overrides some of the original
      // options of the "my-notif" registered type
      this.$q.notify({
        type: 'my-notif',
        icon: 'contactless',
        message: `This notification is using a custom type.`,
        caption: `It overrides the type's default icon and color.`,
        color: 'primary',
        progress: true,
        textColor: 'white'
      })
    },
    triggerPositive () {
      this.$q.notify({
        type: 'positive',
        progress: true,
        classes: "app-font",
        message: `บันทึกรหัสผ่านเรียบร้อยแล้ว`
      });
      this.$refs.qDialog.hide();
      this.old_password = "";
      //this.$emit("passwordUpdated", true);
    },
    triggerNegative () {
      this.$q.notify({
        type: 'negative',
        classes: "app-font",
        message: `ไม่สามารถแก้ไขรหัสผ่านได้ `
      })
    },

    triggerWarning (msg_text) {
      this.$q.notify({
        type: 'warning',
        classes: "app-font",
        message: msg_text
      })
    },
    restrictInput(event) {
      let allowedKeys = [8, 33, 64, 35, 36, 37, 42]
      let key = event.keyCode;
      if ((key >= 48 && key <= 57) || 
          (key >= 65 && key <= 90) || 
          (key >= 97 && key <= 122) || allowedKeys.includes(key)) {
        //console.log("Restrict Pass key:" + key + " value : " + String.fromCharCode(key));
      } else {
        event.preventDefault();
        //console.log("Restrict Not Pass key:" + key + " value : " + String.fromCharCode(key));
      }
    },
    onHide() {
      //console.log("onHide");
      this.$emit("onHide", "");
      //this.$parent.scode_dialog_show = false;
    }
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //console.log('MemberPasswordDialog mounted.');
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
      this.new_password = null;
      this.confirm_password = null;
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN')
  },
  created () {
    /**
     * The reason we have this here
     * is that the type needs to be
     * registered before using it.
     *
     * The best place would be a boot file instead
     * of a .vue file, otherwise it'll keep on
     * registering it every time your component
     * gets to be used :)
     */

    this.$q.notify.registerType('my-notif', {
      icon: 'announcement',
      progress: true,
      color: 'brown',
      textColor: 'white',
      classes: 'glossy'
    })
  }
 
}
