import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Signin from '../views/Signin.vue'
import Diagram from "../views/Diagram.vue"
import UplineTree from "../views/UplineTree.vue"
import LeftTeam from "../views/LeftTeam.vue"
import RightTeam from "../views/RightTeam.vue"
import SponsorTeam from "../views/SponsorTeam.vue"
import DailyBonus from "../views/DailyBonus.vue"
import MonthlyBonus from "../views/MonthlyBonus.vue"
import TranHistoryList from "../views/TranHistoryList.vue"
import BillHoldList from "../views/BillHoldList.vue"
import ProductHoldList from "../views/ProductHoldList.vue"
import TranBillReport from "../views/TranBillReport.vue"
import EPoint from "../views/EPoint.vue"
import EPointTransfer from "../views/EPointTransfer.vue"
import EPointWithdraw from "../views/EPointWithdraw.vue"
import EStoreOrdersHistory from "../views/EStoreOrdersHistory.vue"
import EStore from "../views/EStore.vue"
import MemberOrdersHistory from "../views/MemberOrdersHistory.vue"
import Registration from "../views/Registration.vue"
import Main from "../views/Main.vue"
import MemberProfile from "../views/MemberProfile.vue"
import Seminar from "../views/Seminar.vue"
import store from '../store'

Vue.use(VueRouter)

const routes = [
  /*
  {
    path: '/',
    name: 'Home',
    component: Home
  },*/
  {
    path: '/',
    name: 'Root',
    component: Signin,
    meta: {
      title: 'Login',
    }  
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home',
    }  
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
    meta: {
      title: 'Login',
    }  
  },
  {
    path: '/diagram',
    name: 'Diagram',
    component: Diagram,
    meta: {
      title: 'DHX Diagram',
      requiresAuth: true,
    }
  },  
  {
    /*path: '/uplinetree/:mcode',*/
    path: '/uplinetree',
    name: 'UplineTree',
    component: UplineTree,
    meta: {
      title: 'Upline Chart',
      requiresAuth: true,
    }
  },
  {
    path: '/leftteam',
    name: 'LeftTeam',
    component: LeftTeam,
    meta: {
      title: 'Upline Left Team',
      requiresAuth: true,
    }
  }, 
  {
    path: '/rightteam',
    name: 'RightTeam',
    component: RightTeam,
    meta: {
      title: 'Upline Right Team',
      requiresAuth: true,
    }
  }, 
  {
    path: '/sponsorteam',
    name: 'SponsorTeam',
    component: SponsorTeam,
    meta: {
      title: 'Sponsor Team',
      requiresAuth: true,
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  
  {
    path: '/tranbillreport',
    name: 'TranBillReport',
    component: TranBillReport,
    meta: {
      title: 'Transfer PV Report',
      requiresAuth: true,
    }
  },
  {
    path: '/tranhistorylist',
    name: 'TranHistoryList',
    component: TranHistoryList,
    meta: {
      title: 'Transfer PV',
      requiresAuth: true,
    }
  },
  {
    path: '/billholdlist',
    name: 'BillHoldList',
    component: BillHoldList,
    meta: {
      title: 'Bill Hold',
      requiresAuth: true,
    }
  },
  {
    path: '/productholdlist',
    name: 'ProductHoldList',
    component: ProductHoldList,
    meta: {
      title: 'Product Hold',
      requiresAuth: true,
    }
  },
  {
    path: '/epoint',
    name: 'EPoint',
    component: EPoint,
    meta: {
      title: 'e-Point',
      requiresAuth: true,
    }
  },
  {
    path: '/epointtransfer',
    name: 'EPointTransfer',
    component: EPointTransfer,
    meta: {
      title: 'e-Point Transfer',
      requiresAuth: true,
    }
  },
  {
    path: '/epointwithdraw',
    name: 'EPointWithdraw',
    component: EPointWithdraw,
    meta: {
      title: 'e-Point Withdraw',
      requiresAuth: true,
    }
  },
  {
    path: '/estore',
    name: 'EStore',
    component: EStore,
    meta: {
      title: 'E-Store',
      requiresAuth: true,
    }
  },
  {
    path: '/estoreordershistory',
    name: 'EStoreOrdersHistory',
    component: EStoreOrdersHistory,
    meta: {
      title: 'E-Store Orders',
      requiresAuth: true,
    }
  },
  {
    path: '/dailybonus',
    name: 'DailyBonus',
    component: DailyBonus,
    meta: {
      title: 'Daily Bonus',
      requiresAuth: true,
    }
    /*
    ,beforeEnter: (to, from, next) => {
      if (to.param.id && to.params.hash) {
        next();
      } else {
        next({ name: "Signin"});
      }
    } */
  },
  {
    path: '/monthlybonus',
    name: 'MonthlyBonus',
    component: MonthlyBonus,
    meta: {
      title: 'Monthly Bonus',
      requiresAuth: true,
    }
  },
  {
    path: '/memberordershistory',
    name: 'MemberOrdersHistory',
    component: MemberOrdersHistory,
    meta: {
      title: 'Orders History',
      requiresAuth: true,
    },
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
    meta: {
      title: 'Registration',
      requiresAuth: true,
    },
  },
  {
    path: '/main',
    name: 'Main',
    component: Main,
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/memberprofile',
    name: 'MemberProfile',
    component: MemberProfile,
    meta: {
      title: 'Member Profile',
      requiresAuth: true,
    },
  },
  {
    path: '/seminar',
    name: 'Seminar',
    component: Seminar,
    meta: {
      title: 'Seminar',
      requiresAuth: true,
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
/*
router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (store.state.SESSION_MCODE_LOGIN.length == parseInt(store.state.APP_MCODE_LENGTH)) {
      next();
    } else {
      next({ path: '/signin/' });
    }
  }
  next();
});
*/
router.beforeEach((to, from, next) => {
  // Fisrt step must restore state from local/session storage
  const serializedState = sessionStorage.getItem('appKHWMemberState')
  if (serializedState) {
    const savedState =  JSON.parse(serializedState)
    store.replaceState(savedState)
  }
  let ui_size;
  if (screen.width > 800) {
    //console.log('screen large');
    ui_size = "D";
  }
  else {
    //console.log('screen small');
    ui_size = "M";
  }
  store.set("UI_SIZE", ui_size);

  if (to.matched.some(route => route.meta.requiresAuth) && store.state.SESSION_MCODE_LOGIN.length == parseInt(store.state.APP_MCODE_LENGTH)) {
    //let currentUsername = get('SESSION_USERNAME');
    var currentUsername = store.state.SESSION_MCODE_LOGIN;
    console.log("before Each check MCode : " + store.state.SESSION_MCODE_LOGIN);
    //if (store.state.SESSION_USERNAME.length > 0) {
    //console.log("before Each check Username : " + currentUsername);
    if (currentUsername != "") {
      next();
    } else {
      next({ path: '/signin/' });
    }
  }
  next();
});

export default router
