//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'
import DistrictDialog from '@/components/DistrictDialog.vue'
import MemberListDialog from '@/components/MemberListDialog.vue'

export default {
  components: {
    DistrictDialog,
    MemberListDialog,
  },
  name: 'EStoreCart',
  props: ['cart_info'],
  data() {
    return {
      member_list_dialog_show: false,
      address_district_dialog_show: false,
      bill_no : "",
      epoint_balance : 0,
      isPwd: true,
      epoint_password:"",
      pagination: {
        rowsPerPage: 0
      },
      loading: false,
      query:"1",
      branchList: null,
      datalist: [],
      selected: [],
      columns: [
        { name: 'prodcode', align: 'center', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'prodname', align: 'left', label: 'ชื่อสินค้า', field: 'prodname'},
        { name: 'pv', align: 'right', label: 'PV', field: 'pv' },
        { name: 'price', align: 'right', label: 'ราคา', field: 'unit_price'},
        { name: 'quantity', align: 'right', label: 'จำนวน', field: 'quantity'},
        { name: 'total_pv', align: 'right', label: 'รวม PV', field: 'total_pv'},
        { name: 'total_ba', align: 'right', label: 'รวมเงิน', field: 'price'}
      ],
      delivery_cost: 0,
      total_amount: 0,
      total_pv: 0,
      total_items: 0,
      no_delivery_item_count: 0,
      provinceList: null,
      provinceOptions: this.provinceList,
      for_mcode: this.cart_info.for_mcode,
      for_mname: this.cart_info.for_mname,
      maddress_btn: "",
      caddress_btn: "hidden",
      delivery_by_disable: false,
      submit_btn_disable: true,
      cart_info_init : {
                    cart_no: "",
                    type: "1",
                    for_mcode: "",
                    for_mname: "",
                    delivery_by: "2",
                    branch_selected: "", 
                    receiver_name: "",
                    address1: "",
                    address2: "",
                    province_selected: "", 
                    zipcode: "",
                    phone_no: "",
                    mobile_no: "" 
                  }
    }
  },
  methods: {
    refresh (done) {
        setTimeout(() => {
          this.fetchEPointBalance();
          done();
        }, 1000)
    },
    mcode_selected: function (selected) {
        this.for_mcode = selected.mcode;
        this.fetchMcodeName();
    },
    openMemberDialog: function() {
        this.member_list_dialog_show = true;
        this.$refs.member_list_dialog_ref.fetchDataList();
    },
    member_list_dialog_hide() {
        this.member_list_dialog_show = false;
    },
    district_dialog_hide() {
      this.address_district_dialog_show = false;
    },
    setContactAddress(province, district, subdistrict, zipcode) {
          if (province.length > 0 && district.length > 0 && subdistrict.length > 0) {
            let district_abbr = "อ.";
            let subdistrict_abbr = "ต.";
            if (province == "กรุงเทพฯ" || province == "กรุงเทพมหานคร") {
              district_abbr = "เขต";
              subdistrict_abbr = "แขวง";
            }
            this.cart_info.address2 =  subdistrict_abbr + subdistrict + " " + district_abbr + district;
            this.cart_info.zipcode = zipcode;
            this.setProvinceSelected(province);
          }  
    },
    confirm_post() {
      let delivery_method = "การรับสินค้า : <b>";
      let cart_info = this.cart_info;
      if (cart_info.delivery_by == "1") {
        delivery_method = delivery_method + "บริษัทจัดส่ง ><b><br>" + 
                          " ผู้รับ : " + cart_info.receiver_name + "&nbsp;&nbsp;(มือถือ : " + cart_info.mobile_no +")</br>" + 
                          " ที่อยู่ : " + cart_info.address1 + "" + 
                          "&nbsp;&nbsp;" + cart_info.address2 + 
                          "&nbsp;" + cart_info.province_selected.label + "&nbsp;&nbsp;"+ cart_info.zipcode +"</br>"
      } else {
        delivery_method = delivery_method + "รับที่สาขา > " + cart_info.branch_selected.label + "<b>"
      }
      let self = this;
      this.$q.dialog({
        title: "ยืนยันการสั่งซื้อสินค้า",
        message: " ผู้ซื้อ : <b>" + self.for_mcode + " - " + self.for_mname + "</b></br>" +
                 " ประเภท : <b>" + self.type_description + "</b></br>" + 
                 " ยอดรวม PV : <b>" + self.formatnumber(self.total_pv,2) + "</b></br>" + 
                 " ค่าสินค้า + ค่าขนส่ง : <b>" + self.formatnumber(self.total_amount_net,2) + "</b></br>" + 
                 delivery_method,
        persistent: true,
        html: true,
        class: "app-font",
        style: "background-color:#E8F5E9; color:#000000;",
        ok: {
          label: "ตกลง",
          color: 'positive'
        },
        cancel: {
          label: "ยกเลิก"
        },
      }).onOk(() => {
        this.post_cart();
        // console.log('>>>> OK')
      }).onOk(() => {
        // console.log('>>>> second OK catcher')
      }).onCancel(() => {
        // console.log('>>>> Cancel')
      }).onDismiss(() => {
        // console.log('I am triggered on both OK and Cancel')
      })
    },
    post_cart() {
        let self = this;
        const params = new URLSearchParams();
        
        params.append('number', self.cart_info.cart_no);
        params.append('type', self.cart_info.type);
        params.append('receiver', self.cart_info.receiver_name);
        params.append('address1', self.cart_info.address1);
        params.append('address2', self.cart_info.address2);
        params.append('province', self.cart_info.province_selected.label);
        params.append('zip', self.cart_info.zipcode);
        params.append('telephone', self.cart_info.phone_no);
        params.append('mobile', self.cart_info.mobile_no);
        params.append('receive_by', self.cart_info.delivery_by);
        params.append('delivery', self.delivery_cost);
        params.append('for_mcode', self.cart_info.for_mcode);
        params.append('password', self.epoint_password);
        params.append('receiving_location',self.cart_info.branch_selected.data);

        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },  
          url: self.url_api + "MemberClient/eStore.Billing.Temp.Post.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let result = responseDoc.getElementsByTagName('result_value')[0].textContent;
          let result_txt = responseDoc.getElementsByTagName('result_txt')[0].textContent;
          let bill_no = responseDoc.getElementsByTagName('bill_no')[0].textContent;
          if (result == "1") {
            self.triggerPositive("เลขที่บิลของคุณคือ : " + bill_no);
            self.$emit("updateCartInfo",self.cart_info_init);
            self.$emit("changeTab","history");
          } else {
            self.triggerNegative(result_txt);
          }

        })
        .catch(function (error) {
          self.triggerNegative("ไม่สามารถบันทึกรายการได้");
          console.log("Error :" + error);
        });
    },
    validate_form() {
      let delivery_address_valid = true;
      let epoint_valid = true;
      /*
      self.cart_info.receiver_name = responseDoc.getElementsByTagName('receiver')[0].textContent;
          self.cart_info.address1 = responseDoc.getElementsByTagName('address1')[0].textContent;
          self.cart_info.address2 = responseDoc.getElementsByTagName('address2')[0].textContent;
          self.cart_info.zipcode = responseDoc.getElementsByTagName('zip')[0].textContent;
          self.cart_info.phone_no = responseDoc.getElementsByTagName('telephone')[0].textContent;
          self.cart_info.mobile_no = responseDoc.getElementsByTagName('mobile')[0].textContent;
          self.setProvinceSelected(responseDoc.getElementsByTagName('province')[0].textContent);
      */
      if (this.cart_info.delivery_by == "1" && this.for_mcode.length == this.mcode_length) {
        let error_message = "กรุณาป้อน ";
        let error_detail = "";
        if (this.cart_info.receiver_name.length < 5) {
          delivery_address_valid = false;
          error_detail = error_detail + " ชื่อผู้รับ";
        }
        if (this.cart_info.address1.length < 5) {
          delivery_address_valid = false;
          error_detail = error_detail + " ที่อยู่สำหรับจัดส่ง";
        }
        if (this.cart_info.zipcode.length < 5) {
          delivery_address_valid = false;
          error_detail = error_detail + " รหัสไปรษณีย์";
        }
        if (this.cart_info.mobile_no.length < 10) {
          delivery_address_valid = false;
          error_detail = error_detail + " เบอร์มือถือ";
        }
        if (error_detail.length > 0) {
          this.triggerNegative(error_message + error_detail);
        }
      }
      
      if (parseFloat(this.epoint_balance) < parseFloat(this.total_amount_net)) {
        this.triggerNegative("e-Point มีไม่เพียงพอ ( e-Point คงเหลือ : " + this.formatnumber(parseFloat(this.epoint_balance),2) + " ยอดที่ซื้อ : " + this.formatnumber(parseFloat(this.total_amount_net),2) + " )");
        epoint_valid = false;
      }

      if ( epoint_valid && delivery_address_valid && parseFloat(this.total_amount) > 0 && this.epoint_password.length > 0 && this.for_mname != ""  ) {
        this.submit_btn_disable = false;
      } else {
        this.submit_btn_disable = true;
      }

    },
    calculate_delivery_cost() {
      /*
      if (parseFloat(this.total_pv) < 1000 && this.cart_info.delivery_by == "1") // 1 = company delivery
      {
        this.delivery_cost = 50;
      } else {
        this.delivery_cost = 0;
      }*/
        //alert("calculate_delivery_cost : " + this.cart_info.delivery_by);
        let self = this;
        if (self.cart_info.delivery_by == "1") {
            self.axios({
              method: "post",
              headers: {
                'Authorization': self.jwt_token
              },  
              url: self.url_api + "MemberClient/eStore.Deliver.Cost.JSON.aspx"
            })
            .then(function (response) {
              let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
              let cal_from = responseDoc.getElementsByTagName('cal_from')[0].textContent;
              let pv_condition = parseFloat(self.replaceAll(responseDoc.getElementsByTagName('pv_condition')[0].textContent,",",""));
              let ba_condition = parseFloat(self.replaceAll(responseDoc.getElementsByTagName('ba_condition')[0].textContent,",",""));
              //alert("cal_from : " + cal_from);
              //alert("pv_condition : " + pv_condition);
              //alert("ba_condition : " + ba_condition);
              if (cal_from == "1") {
                if (self.total_pv >= pv_condition) {
                  self.delivery_cost = 0.00;
                } else {
                  self.delivery_cost = parseFloat(self.replaceAll(responseDoc.getElementsByTagName('cost')[0].textContent,",",""));
                }	
              } else {
                if (cal_from == "2") {
                  if (self.total_amount >= ba_condition) {
                    self.delivery_cost = 0.00;
                  } else {
                    self.delivery_cost = parseFloat(self.replaceAll(responseDoc.getElementsByTagName('cost')[0].textContent,",",""));
                  }	
                }
              }
              //self.total_amount_net = self.total_amount + self.deliver_cost;
              //alert("delivery_cost = " + self.delivery_cost.toString());
            })
            .catch(function (error) {
              console.log("Error :" + error);
            });
        }
        
    },
    confirm_remove(item) {
      this.$q.dialog({
        title: "ยืนยันการลบรายการ",
        message: " รายการที่ : <b>" + item.rownum + "</b></br>" + 
                 " สินค้า : <b>" + item.prodcode + " - " + item.prodname + "</b></br>" + 
                 " PV : <b>" + item.pv + "</b>&nbsp;&nbsp;&nbsp;ราคา : <b>" + item.price + "</b></br>" + 
                 " จำนวน : <b>" + item.quantity + "</b>",
        persistent: true,
        html: true,
        class: "app-font",
        style: "border: 1px solid #EE4E2D; background-color:#FFEEEA; color:#EE4E2D;",
        ok: {
          label: "ตกลง",
          color: 'positive'
        },
        cancel: {
          label: "ยกเลิก"
        },
      }).onOk(() => {
        this.removeItem(item.id);
        // console.log('>>>> OK')
      }).onOk(() => {
        // console.log('>>>> second OK catcher')
      }).onCancel(() => {
        // console.log('>>>> Cancel')
      }).onDismiss(() => {
        // console.log('I am triggered on both OK and Cancel')
      })
    },
    removeItem: function (id) {
        let self = this;
        const params = new URLSearchParams();
        params.append('number', self.cart_info.cart_no);
        params.append('id',id)
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/eStore.Billing.Temp.DeleteItem.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let result = responseDoc.getElementsByTagName('status')[0].textContent;
          let result_txt = responseDoc.getElementsByTagName('message')[0].textContent;
          if (result == "1") {
            self.triggerPositive(result_txt);
          } else {
            self.triggerNegative(result_txt);
          }
          self.fetchBillInfo();
        })
        .catch(function (error) {
          self.triggerNegative("ไม่สามารถทำการลบรายการ");
          console.log("Error :" + error);
        });
    },
    triggerPositive (msg_text) {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: msg_text,
          classes:'app-font'
        });
    },
    triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
    },
    use_maddress: function() {
      this.maddress_btn = "hidden";
      this.caddress_btn = "";
      this.use_address(this.session_mcode);
    },
    use_caddress: function() {
      this.maddress_btn = "";
      this.caddress_btn = "hidden";
      if (this.for_mname != "" && this.for_mcode.length == this.mcode_length) {
        this.use_address(this.for_mcode);
      } else {
        this.cart_info.receiver_name = "";
        this.cart_info.address1 = "";
        this.cart_info.address2 = "";
        this.cart_info.zipcode = "";
        this.cart_info.phone_no = "";
        this.cart_info.mobile_no = "";
        this.setProvinceSelected("กรุงเทพฯ");
      }
    },
    use_address: function(mcode) {
        let self = this;
        const params = new URLSearchParams();
        params.append('mcode', mcode);
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },  
          url: self.url_api + "MemberClient/GetMAddressInfo.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.cart_info.receiver_name = responseDoc.getElementsByTagName('receiver')[0].textContent;
          self.cart_info.address1 = responseDoc.getElementsByTagName('address1')[0].textContent;
          self.cart_info.address2 = responseDoc.getElementsByTagName('address2')[0].textContent;
          self.cart_info.zipcode = responseDoc.getElementsByTagName('zip')[0].textContent;
          self.cart_info.phone_no = responseDoc.getElementsByTagName('telephone')[0].textContent;
          self.cart_info.mobile_no = responseDoc.getElementsByTagName('mobile')[0].textContent;
          self.setProvinceSelected(responseDoc.getElementsByTagName('province')[0].textContent);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
    },
    paddingMcode: function() {
        if (this.for_mcode.length < this.mcode_length) {
          this.for_mcode = this.paddingCode(this.for_mcode);
        }
        this.fetchMcodeName();
    },
    fetchMcodeName: function () {
        let self = this;
        if (self.for_mcode.length == self.mcode_length)
        {
            const params = new URLSearchParams();
            params.append('mcode', self.for_mcode);
            self.axios({
              method: "post",
              headers: {
                'Authorization': self.jwt_token
              },  
              url: self.url_api + "MemberClient/GetMcodeInfo.JSON.aspx",
              data: params
            })
            .then(function (response) {
              let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
              let record_count = responseDoc.getElementsByTagName('recordcount')[0].textContent;

              if (record_count > 0) {
                self.for_mname = responseDoc.getElementsByTagName('name_t')[0].textContent
                self.cart_info.for_mcode = self.for_mcode;
                self.cart_info.for_mname = self.for_mname;
                self.cart_info.receiver_name = responseDoc.getElementsByTagName('receiver')[0].textContent;
                self.cart_info.address1 = responseDoc.getElementsByTagName('address1')[0].textContent;
                self.cart_info.address2 = responseDoc.getElementsByTagName('address2')[0].textContent;
                self.cart_info.zipcode = responseDoc.getElementsByTagName('zip')[0].textContent;
                self.cart_info.phone_no = responseDoc.getElementsByTagName('telephone')[0].textContent;
                self.cart_info.mobile_no = responseDoc.getElementsByTagName('mobile')[0].textContent;
                self.setProvinceSelected(responseDoc.getElementsByTagName('province')[0].textContent);
              } else {
                self.for_mcode = "";
                self.for_mname = "";
                self.cart_info.for_mcode = "";
                self.cart_info.for_mname = "";
                //self.cart_info.delivery_by = "2";
                //self.cart_info.branch_selected = self.branchList[0];
                self.cart_info.receiver_name = "";
                self.cart_info.address1 = "";
                self.cart_info.address2 = "";
                self.cart_info.province_selected = self.provinceList[0];
                self.cart_info.zipcode = "";
                self.cart_info.phone_no = "";
                self.cart_info.mobile_no = "";
                self.triggerNegative("ไม่พบข้อมูล (พ้นสภาพ หรือ ขาดการติดต่อ)");
              }
              self.validate_form();
              //console.log("for_mname : " + self.for_mname);
            })
            .catch(function (error) {
              console.log("Error :" + error);
            });
        }
        
    },
    setBranchSelected(selected_value) {
      var found = this.branchList.find(function(element) { 
        return element.value == selected_value; 
      });
      this.cart_info.branch_selected = found;
    },    
    fetchReceivingLocation: function () {
        let self = this;
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/ReceivingLocation.ListAll.JSON.aspx"
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.branchList = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          if (self.cart_info.branch_selected == "") {
            self.cart_info.branch_selected = self.branchList[0];
          }
          //console.log("ReceivingLocation");
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
    },
    createBill: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('date', self.session_date);
        params.append('mcode', self.session_mcode);
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/eStore.Billing.Temp.Insert.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.bill_no = responseDoc.getElementsByTagName('bill_no')[0].textContent;
          self.cart_info.cart_no = self.bill_no;
          self.fetchBillInfo();
          //console.log("Create Cart No : " + self.bill_no);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
    },
    setProvinceSelected(selected_value) {
      var found = this.provinceList.find(function(element) { 
        return element.label == selected_value; 
      });
      this.cart_info.province_selected = found;
    },    
    fetchProvince: function () {
      let self = this;
      self.axios({
      method: "post",
        url: self.url_api + "MemberClient/Province.ListAll.JSON.aspx"
      })
      .then(function (response) {
        //console.log("province result :" + response.data[0].label);
        self.provinceList = response.data;
        if (self.cart_info.province_selected == "") {
          self.cart_info.province_selected = self.provinceList[0];
        } 
      })
      .catch(function (error) {
        console.log("Error :" + error);
      });
    },
    fetchEPointBalance: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('mcode', self.session_mcode);
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },  
          url: self.url_api + "MemberClient/ePoint.GetBalance.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.epoint_balance = parseFloat(responseDoc.getElementsByTagName('epoint')[0].textContent.replace(",",""));
          //console.log("epoint_balance : " + self.epoint_balance);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
    },
    fetchBillInfo: function () {
        let self = this;
        const params = new URLSearchParams();
        //console.log("fetchBillInfo cart_no : " +self.cart_info.cart_no);
        params.append('number', self.cart_info.cart_no);
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },         
          url: self.url_api + "MemberClient/eStore.Billing.Temp.View.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.epoint_balance = parseFloat(responseDoc.getElementsByTagName('epoint_balance')[0].textContent.replace(",",""));
          //console.log("count_item : " + responseDoc.getElementsByTagName('count_item')[0].textContent);
          if ( parseInt(responseDoc.getElementsByTagName('count_item')[0].textContent ) > 0) {
            self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          } else {
            self.datalist = [];
          }
          self.total_items = self.datalist.length;
          self.total_amount = parseFloat(responseDoc.getElementsByTagName('total_ba')[0].textContent.replace(",",""));
          self.total_pv = parseFloat(responseDoc.getElementsByTagName('total_pv')[0].textContent.replace(",",""));
          self.calculate_delivery_cost();
          self.no_delivery_item_count = parseInt(responseDoc.getElementsByTagName('no_delivery')[0].textContent);
          if (self.no_delivery_item_count > 0) {
            self.triggerNegative("มีสินค้าที่ไม่สามารถดำเนินการจัดส่งได้รวมอยู่");
            self.cart_info.delivery_by = "2";
            self.delivery_by_disable = true;
          } else {
            self.delivery_by_disable = false;
          }
          /*
          self.epoint_balance = responseDoc.getElementsByTagName('receiver')[0].textContent;
          self.epoint_balance = responseDoc.getElementsByTagName('address1')[0].textContent;
          self.epoint_balance = responseDoc.getElementsByTagName('address2')[0].textContent;
          self.epoint_balance = responseDoc.getElementsByTagName('province')[0].textContent;
          self.epoint_balance = responseDoc.getElementsByTagName('zip')[0].textContent;
          self.epoint_balance = responseDoc.getElementsByTagName('telephone')[0].textContent;
          self.epoint_balance = responseDoc.getElementsByTagName('mobile')[0].textContent;
          self.epoint_balance = responseDoc.getElementsByTagName('totalvat')[0].textContent;
          self.epoint_balance = responseDoc.getElementsByTagName('totnovat')[0].textContent;
          self.epoint_balance = responseDoc.getElementsByTagName('total_pv')[0].textContent;
          self.epoint_balance = responseDoc.getElementsByTagName('epoint_balance')[0].textContent;
          self.epoint_balance = responseDoc.getElementsByTagName('no_delivery')[0].textContent;
          */
          self.validate_form();
          //console.log("epoint_balance : " + self.epoint_balance);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
    gotoProductTab: function () {
      //let self = this;
      //alert("product list tab =>" + self.$parent.tab_selected);
      this.$emit("changeTab","product");
    },
    filterFnAutoselect (val, update) {
        // call abort() at any time if you can't retrieve data somehow
        setTimeout(() => {
          update(
            () => {
              if (val === '') {
                this.provinceOptions = this.provinceList
              }
              else {
                const needle = val.toLowerCase()
                this.provinceOptions = this.provinceList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                //console.log("filter result:" + this.provinceOptions[0].label);
                /*
                const result = members.filter((jsonOptions) => {
                  return jsonOptions.label > 25
                })
                this.options = result*/

              }
            },

            // next function is available in Quasar v1.7.4+;
            // "ref" is the Vue reference to the QSelect
            
            ref => {
              if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
              }
            }
          )
        }, 300)
      },

      abortFilterFn () {
        // console.log('delayed filter aborted')
      },
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE'),
      total_amount_net: function() {
        return this.total_amount + this.delivery_cost;
      },
      type_description: function() {
        let type_desc;
        if (this.cart_info.type == "1") {
          type_desc = "ซื้อปกติ";
        } else {
          type_desc = "ซื้อรักษายอด";
        }
        return (type_desc);
      },
      for_mcode_isValid () {
        return this.for_mcode.length == this.mcode_length;
      },
      receiver_name_isValid() {
        return this.cart_info.receiver_name.length >= 5;
      },
      address1_isValid () {
        return this.cart_info.address1.length >= 5;
      },
      zipcode_isValid () {
        return this.cart_info.zipcode.length == 5;
      },
      mobile_no_isValid () {
        return this.cart_info.mobile_no.length == 10;
      }
  },
  mount() {
      alert("mount cart province :" + this.cart_info.province_selected);
      //alert("mount cart");
      /*
      if (this.session_for_mcode != "") {
        this.for_mcode = this.session_for_mcode;
        this.for_mname = this.session_for_mname;
      }*/
  },
  created () {
      //alert("created cart");
      //console.log('Component created.');
      this.fetchReceivingLocation();
      this.fetchEPointBalance();
      this.fetchProvince();
      if (this.cart_info.cart_no == "") {
        this.createBill();
      } else {
        this.fetchBillInfo();
      }
  },
  watch: {
    "cart_info.delivery_by": function() {
      this.calculate_delivery_cost();
      //console.log("==========> watch cart_info.delivery_by <===========");
          /*
          if (this.cart_info.delivery_by == "1") {
            alert("compnay delivery");
          }
          */
    },
    cart_info: {
        handler: function () {
          //console.log("==========> watch cart_info <===========");
          /*
          console.log("province : "+ this.cart_info.province_selected.label);
          console.log("branch selected" + this.cart_info.branch_selected.label);
          */
          //this.calculate_delivery_cost();
          this.$emit("updateCartInfo",this.cart_info);
        },
        deep: true
    },
    epoint_password: function() {
      this.validate_form();
    }

  }

}

