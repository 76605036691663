//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { openURL } from 'quasar'
import { get } from 'vuex-pathify'
import store from '../store/index'
import MbscDatePicker from '@/components/MbscDatePicker.vue'
import MemberOrdersDetailDialog from '@/components/MemberOrdersHistoryDetailDialog.vue'

export default {
  components: {
    MbscDatePicker,
    MemberOrdersDetailDialog
  },
  data () {
    return {
      init: false,
      orders_no:"",
      member_orders_detail_dialog_show: false,
      searchText:"",
      from_date:"",
      to_date:"",
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'date', align: 'center', label: 'วันที่', field: 'date'},
        { name: 'number', align: 'center', label: 'เลขที่เอกสาร', field: 'number'},
        { name: 'bill_type', align: 'center', label: 'ประเภทเอกสาร', field: 'bill_type'},
        { name: 'type', align: 'center', label: 'แผน', field: 'type'},
        { name: 'total_ba', align: 'right', label: 'รวมเงิน', field: 'total_ba' },
        { name: 'total_pv', align: 'right', label: 'รวม PV', field: 'total_pv' }
      ],
    }
  },
  methods: {
      refresh (done) {
        setTimeout(() => {
          this.getData();
          done();
        }, 1000)
      },
      clearFilter: function () {
        this.searchText = "";
        this.getData();
      },
      openDetailDialog: function(number) {
        this.orders_no = number;
        this.member_orders_detail_dialog_show = true;
      },
      member_orders_detail_dialog_hide () {
        this.member_orders_detail_dialog_show = false;
        //console.log("hide set member_orders_detail_dialog_show = " + this.member_orders_detail_dialog_show);
      },
      openDeliverInfo: function( deliver_code, deliver_url ) {
        openURL(deliver_url + deliver_code);
      },
      /*
      exportExcel: function() {
          let self = this;
          openURL(self.url_api + 'Bonus/Report.Daily.Bonus.ByPeriodDate.Excel.aspx')    
      },*/
      requestRowClick: function(selected_mcode, selected_row) {
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
          }
      },
      fetchBonusDate: function () {
        //console.log("fetch bonus date");
        const params = new URLSearchParams();
        params.append("mcode", this.session_mcode);
        let self = this;
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/GetBonusDateRange.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let start_date = responseDoc.getElementsByTagName('startdate')[0].textContent;
          //let end_date = responseDoc.getElementsByTagName('enddate')[0].textContent;
          //console.log("start_date :" + start_date + " end_date : " + end_date);
          self.from_date = start_date;
          self.to_date = self.session_date;
          self.getData();
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      editMode() {
        //console.log("click");
      },
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_date = e;
            break;
          case "ถึงวันที่":
            this.to_date = e;
            break;  
        }
      },
      getData: function () {
        var self = this;
        if (self.init) {
          self.loading = true;
        }
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('mcode', self.session_mcode);
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('from_date', self.from_date);
        params.append('to_date', self.to_date);
        params.append('findword', self.searchText);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 0,
          url: self.url_api + 'MemberClient/GetBillHistory.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            } else {
              self.datalist = [];
              self.selected = [];
            }
            self.total_record = record_count;
            self.total_page = page_count;
            self.loading = false;
            self.init = true;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {
      //console.log('Component mounted.');
      this.fetchBonusDate();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE')
  },
  watch: {
    pageID: function () {
      if (this.init) {
        this.getData();
      }  
    },
    from_date: function () {
      if (this.init) {
        this.getData();
      }  
    },
    to_date: function () {
      if (this.init) {
        this.getData();
      }  
    }
  },
  created () {
      store.set("APP_TOOLBAR_TITLE","ยอดซื้อส่วนตัว: " + this.session_mcode);
      //console.log('Component created.');
      this.$q.loading.show();
  }    
}   
