//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { get } from 'vuex-pathify';
import store from '../store/index';

export default {
  data () {
    return {
      init: false,
      mcode_current: "",
      from_level: 0,
      to_level: 999,
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'lvl', align: 'center', label: 'ชั้น', field: 'lvl'},
        { name: 'mcode', align: 'center', label: 'ชื่อสมาชิก', field: 'mcode'},
        { name: 'name_t', align: 'left', label: 'ชื่อสมาชิก', field: 'name_t'},
        { name: 'side', align: 'center', label: 'ด้าน', field: 'side' },
        { name: 'mdate', align: 'center', label: 'วันสมัคร', field: 'mdate' },
        { name: 'last_order_date', align: 'center', label: 'เคลื่อนไหวล่าสุด', field: 'last_order_date' },
        { name: 'rank', align: 'center', label: 'ตน.', field: 'rank' },
        { name: 'ppvtotal', align: 'right', label: 'PV สะสม', field: 'ppvtotal'},
        { name: 'up_code', align: 'center', label: 'รหัสอัพไลน์', field: 'up_code' },
        { name: 'sp_code', align: 'center', label: 'รหัสผู้แนะนำ', field: 'sp_code' },
      ],
      positionList: [],
      position_selected: { label: "ทุกตำแหน่ง", value: "all" },
      searchText:"",
      last_order_date:"",
      pvteam: 0,
      pvcompleteteam: 0
    }
  },
  methods: {
      refresh (done) {
        setTimeout(() => {
          this.getData();
          done();
        }, 1000)
      },
      dataFilter: function () {
        if (this.last_order_date.length > 0 || this.searchText.length > 0) {
          this.getData();
        }
      },
      clearFilter: function (input_name) {
        this.pageID = 1;
        this.from_level = 0;
        this.to_level = 999;
        if (input_name == "last_order_date") { this.last_order_date = ""; }
        if (input_name == "searchText") { this.searchText = ""; }
        this.getData();
      },
      gotoUplineTree: function(mcode_curr) {
          let self = this;
          self.$router.push( { name: 'UplineTree', params: { mcode: mcode_curr } });
      },
      requestRowClick: function(selected_mcode, selected_row) {
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
          }
      },
      fetchPosition: function () {
        console.log("fetch position");
  
        let self = this;
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/Position.ListAll.JSON.aspx"
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.positionList = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          self.position_selected = self.positionList[0];

        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_bonus_date = e;
            break;
          case "ถึงวันที่":
            this.to_bonus_date = e;
            break;  
        }
      },
      getData: function () {
        var self = this;
        if (self.init) {
          self.loading = true;
        }
        self.rowsPerFetch = 100;//500;//self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('mcode', self.mcode_current);
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('findword', self.searchText);
        params.append('fromlv', self.from_level);
        params.append('tolv', self.to_level);
        params.append('last_order_date', self.last_order_date);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0,
          url: self.url_api + 'MemberClient/GetMemberUpTeamR.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            let pvteam = self.formatnumber(parseFloat(self.replaceAll(responseDoc.getElementsByTagName('pvteam')[0].textContent,",","")),2);
            let pvcompleteteam = self.formatnumber(parseFloat(self.replaceAll(responseDoc.getElementsByTagName('totalpvmcode')[0].textContent,",","")),0);
            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              //self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
              self.total_record = record_count;
              self.total_page = page_count;
              self.pvteam = pvteam;
              self.pvcompleteteam = pvcompleteteam;
              self.init = true;
              self.$q.loading.hide();
              self.loading = false;
            } else {
              self.datalist = [];
              self.selected = [];
              self.total_record = 0;
              self.total_page = 0;
              self.pvteam = 0;
              self.pvcompleteteam = 0;
              self.loading = false;
              self.init = true;
              self.$q.loading.hide();
            }
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {
      //console.log('Component mounted.');
      this.mcode_current = this.session_mcode;
      this.getData();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN')
  },
  watch: {
    pageID: function () {
      if (this.init) {
        this.getData();
      }  
    },
    from_level: function () {
      if (this.init) {
        this.getData();
      } 
    },
    to_level: function () {
      if (this.init) {
        this.getData();
      } 
    }
  },
  created () {
      store.set("APP_TOOLBAR_TITLE","ทีมด้านขวา : "+ this.session_mcode);
      //console.log('Component created.');
      //this.fetchPosition();
      this.$q.loading.show();
  }    
}   
