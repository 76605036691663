//
//
//
//
//
//

// @ is an alias to /src
import SponsorTeamPage from '@/components/SponsorTeamPage.vue';
import store from '../store/index';
import { get } from 'vuex-pathify';

export default {
  name: 'SponsorTeam',
  components: {
    SponsorTeamPage
  },
  computed: {
      session_mcode: get('SESSION_MCODE_LOGIN')
  },
  created () {
    store.set("APP_TOOLBAR_TITLE","ทีมงานแผน B : " + this.session_mcode);
    this.$q.loading.show();
  }    
}
