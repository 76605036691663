//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


//import { openURL } from 'quasar'
import { get } from 'vuex-pathify'
import store from '../store/index'
import MbscDatePicker from '@/components/MbscDatePicker.vue'

export default {
  components: {
    MbscDatePicker
  },
  data () {
    return {
      init: false,
      ui_size: "",
      typeList_selected: {label: 'ทั้งหมด', value: '0'},
      typeList: [
                    {label: 'ทั้งหมด', value: '0'},
                    {label: 'ได้รับจากโบนัสรายวัน', value: '1'},
                    {label: 'ได้รับจากโบนัสรายเดือน', value: '2'},
                    {label: 'ได้รับจากระบบ', value: '3'},
                    {label: 'ได้รับจากรหัสอื่น', value: '4'},
                    {label: 'โอนให้รหัสอื่น', value: '5'},
                    {label: 'ใช้ชื้อสินค้า', value: '6'},
                    {label: 'ถอน eBonus', value: '7'}
                  ],
      type_filter: '0',
      from_date:"",
      to_date:"",
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'date', align: 'center', label: 'วันที่', field: 'date'},
        { name: 'time', align: 'center', label: 'เวลา', field: 'time'},
        { name: 'type_desc', align: 'left', label: 'ประเภท', field: 'type_desc' },
        { name: 'descript', align: 'left', label: 'รายละเอียด', field: 'descript' },
        { name: 'income', align: 'right', label: 'ได้รับ e-Point', field: 'income' },
        { name: 'outcome', align: 'right', label: 'ใช้ e-Point', field: 'outcome'},
        { name: 'balance', align: 'right', label: 'คงเหลือ', field: 'balance'}
      ],
    }
  },
  methods: {
      /*
      exportPDF: function() {
          let self = this;
          openURL(self.url_api + '../pdf/ePoint.GetBalance.ByDate.PDF.aspx');    
      },
      */
      refresh (done) {
        setTimeout(() => {
          this.getData();
          done();
        }, 1000)
      },
      setUISize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          console.log('screen large');
          this.ui_size = "D";
        }
        else {
          console.log('screen small');
          this.ui_size = "M";
        }
      },
      exportPDF: function() {
          let self = this;
          let actionUrl = self.url_api + "../pdf/ePoint.Statement.PDF.aspx";
          let windowName = "e-Point";
          let windowFeatures = "";
          let data = {
                        Authorization: self.jwt_token,
                        pagesize: self.rowsPerFetch,
                        pageid: self.pageID,
                        from_date: self.from_date,
                        to_date: self.to_date,
                        mcode: self.session_mcode,
                        type: self.type_filter,
                        type_desc: self.type_filter.label
                     };
          this.windowOpenWithPost(actionUrl, windowName, windowFeatures, data);
      },

      /*
      exportExcel: function() {
          let self = this;
          openURL(self.url_api + 'Bonus/Report.Daily.Bonus.ByPeriodDate.Excel.aspx')    
      },*/
      requestRowClick: function(selected_mcode, selected_row) {
          this.current_row = selected_row.rownum;
          //console.log("row click :" + selected_mcode);
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            //this.$emit("onSeleted", selected_row);
          }
      },
      //Bonus/Report.Daily.Bonus.Getlastdate.aspx
      fetchBonusDate: function () {
        //console.log("fetch bonus date");
        const params = new URLSearchParams();
        params.append("mcode", this.session_mcode);

        let self = this;
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/GetBonusDateRange.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let start_date = responseDoc.getElementsByTagName('startdate')[0].textContent;
          //let end_date = responseDoc.getElementsByTagName('enddate')[0].textContent;
          //console.log("start_date :" + start_date + " end_date : " + end_date);
          self.from_date = start_date;
          self.to_date = self.session_date;
          self.getData();
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      editMode() {
        //console.log("click");
      },
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_date = e;
            break;
          case "ถึงวันที่":
            this.to_date = e;
            break;  
        }
      },
      getData: function () {
        var self = this;
        if (self.init) {
          self.loading = true;
        }
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('from_date', self.from_date);
        params.append('to_date', self.to_date);
        params.append('mcode', self.session_mcode);
        params.append('type', self.type_filter);
        params.append('type_desc', self.type_filter.label);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 0, // Let's say you want to wait at least unlimits mins,
          url: self.url_api + 'MemberClient/ePoint.GetBalance.ByDate.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            
            /*
            console.log(responseDoc.getElementsByTagName('json')[0].textContent);
            console.log("record count " + record_count.toString());
            console.log("page count " + responseDoc.getElementsByTagName('pagecount')[0].textContent);
            */

            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            } else {
              self.datalist = [];
              self.selected = [];
            }
            self.total_record = record_count;
            self.total_page = page_count;
            self.loading = false;
            self.init = true;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {
      /*
      window.addEventListener("keyup", e => {
        switch (e.keyCode) {
          case 38:
            alert(e.keyCode);
            break;
          case 40:
            alert(e.keyCode);
            break;
        }    
        /*
        if (String.fromCharCode(e.keyCode)=="&") {

        } else {
            if (String.fromCharCode(e.keyCode)=="(") {

            }
        }
      });*/
  
      //var self = this;
      //console.log('API URL:' + self.url_api + 'Member/Member.View.JSON.aspx');
      //console.log('Component mounted.');
      this.fetchBonusDate();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE')
  },
  watch: {
    pageID: function () {
      if (this.init) {
        this.getData();
      }  
    },
    type_filter: function () {
      if (this.init) {
        this.getData();
      }  
    },
    typeList_selected: function () {
      if (this.init) {
        this.type_filter = this.typeList_selected.value;
      }  
    },
    from_date: function () {
      if (this.init) {
        this.getData();
      }  
    },
    to_date: function () {
      if (this.init) {
        this.getData();
      }  
    },
    "$q.screen.width"() {
      this.setUISize()
    }
  },
  created () {
      store.set("APP_TOOLBAR_TITLE","ประวัติ e-Point");
      //console.log('Component created.');
      this.$q.loading.show();
      this.setUISize();
  }    
}   
