//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'
import EStoreAddToCartDialog from '@/components/EStoreAddToCartDialog.vue'

export default {
  components: {
    EStoreAddToCartDialog
  },
  name: "EstoreProductList",
  props: ["cart_no"],
  data () {
    return {
      ui_size: "",
      item_info: { 
                  cart_no: "", 
                   prodcode: "",  
                   prodname: "",
                   pv: "0",
                   price: "0"
                   /*
                   cart_no: this.cart_no, 
                   prodcode: this.selected[0].prodcode,  
                   prodname: this.selected[0].prodname,
                   pv: this.selected[0].pv,
                   price: this.selected[0].price
                   */
                 },
      estore_add_to_cart_dialog_show: false,
      product_img_url: "",
      pagination: {
        rowsPerPage: 0
      },
      loading: false,
      query:"1",
      datalist: [],
      selected: [],
      columns: [
        { name: 'prodcode', align: 'center', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'prodname', align: 'left', label: 'ชื่อสินค้า', field: 'prodname'},
        { name: 'pv', align: 'right', label: 'PV', field: 'pv' },
        { name: 'price', align: 'right', label: 'ราคา', field: 'price'}
      ],
      columns_mobile: [
        { name: 'prodname', align: 'left', label: 'รายละเอียดสินค้า', field: 'prodname'},
      ]
    }
  },
  methods: {
      refresh (done) {
        setTimeout(() => {
          this.getData();
          done();
        }, 1000)
      },
      setUISize() {
          //if (this.$q.screen.width > 1023) { 
          if (this.$q.screen.width > 800) {
          console.log('screen large');
          this.ui_size = "D";
          }
          else {
          console.log('screen small');
          this.ui_size = "M";
          }
      },
      gotoCartTab () {
        this.$emit("changeTab","cart");
      },
      open_add_to_cart_dialog: function(item) {
        this.item_info.cart_no = this.cart_no;
        this.item_info.prodcode = item.prodcode;
        this.item_info.prodname = item.prodname;
        this.item_info.pv = item.pv;
        this.item_info.price = item.price;
        this.estore_add_to_cart_dialog_show = true;
      },
      estore_add_to_cart_dialog_hide () {
        this.estore_add_to_cart_dialog_show = false;
        //console.log("hide set estore_add_to_cart_dialog_show = " + this.estore_add_to_cart_dialog_show);
      },
      requestRowClick: function(selected_prodcode, selected_row) {
          //console.log("row click :" + selected_mcode);
          if (this.selected.prodcode !== selected_prodcode) {
            this.selected.splice(0,1); //delete item from selected array 
            this.selected.push(selected_row); //add item from selected array 
            //this.$emit("onSeleted", selected_row);
            this.product_img_url = this.url_api.replace("aspx","Product") + selected_prodcode+".jpg";
            //console.log("product_img_url :" + this.url_api.replace("aspx","Product") + selected_prodcode+".jpg");
          }
      },
      getData: function () {
        var self = this;
        self.loading = true;
        self.rowsPerFetch = 200;//self.appRowsPerFetch;
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0, // Let's say you want to wait at least Unlimits mins,
          url: self.url_api + 'MemberClient/eStore.Product.List.JSON.aspx'
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            //let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
            self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            self.product_img_url = self.url_api.replace("aspx","Product") + self.selected[0].prodcode+".jpg";
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  
  },
  mounted () {
      //let self = this;
      //console.log('EStoreOrdersDetail Dialog mounted.');
      this.getData();
  },
  watch: {
    "$q.screen.width"() {
      this.setUISize()
    },
    /*
    show_status: function () {
      console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    }*/
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN')
  },
  created () {
    this.setUISize();
    //console.log('Component created.');
  }
}
