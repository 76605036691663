//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';
import store from '../store/index';
import { openURL } from 'quasar';

export default {
  name: 'signin',
  data () {
    return {
      mcode: "",
      username: "",
      password: "",
      isPwd: true,
      status_text: "",
      status_color: "light-green-7",
      date_text: "",
      login_btn_disable: false,
      readonly: false,
      login_panel_class: "hidden",
    }
  },      
  methods: {
    login_btn_focus() {
      //this.$refs.login_btn.setFocus();
      //alert("password filled");
      this.loginProcess();
    },
    padZero() {
      //this.username = this.paddingCode(this.username.toString());
      this.$refs.password_input.focus();
    },
    gotoWebsite () {
      openURL("http://www.kingherb.net/"); 
    },
    triggerWarning (msg_text) {
      this.$q.notify({
        type: 'warning',
        message: msg_text,
        classes:'app-font'
      })
    },
    triggerPositive (msg_text) {
      this.$q.notify({
        type: 'positive',
        progress: true,
        message: msg_text,
        classes:'app-font'
      });
    },
    triggerNegative (msg_text) {
      this.$q.notify({
        type: 'negative',
        message: msg_text,
        classes:'app-font'
      })
    },
    loginProcess () {
       let self = this;

       if (self.appShutdownValue != "1") {
          self.login_btn_disable = true;
          self.loading = true;
          const params = new URLSearchParams();
          params.append('mcode', self.username);
          params.append('password', self.password);
          
          //console.log("login mcode: " + self.mcode + " password: " + self.password);

          self.axios({
                method: 'post',
                timeout: 10000,
                url: self.url_api + 'MemberClient/MemberSignIn.aspx',
                data: params
              })
              .then(function (response) {
                  //console.log("result :" + response.data);
                  let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                  let loginResult = responseDoc.getElementsByTagName('loginResult')[0].textContent;
                  //console.log("loginResult:" + loginResult);
                  if (loginResult == "0") {
                      //console.log("Login Fail");
                      let loginErr = "";
                      if (self.appLangUI == "0") {
                        loginErr = responseDoc.getElementsByTagName('loginErr')[0].textContent;
                      } else {
                        loginErr = "Incorrect Member ID and Password.";
                      }
                      console.log("loginErr:" + loginErr);
                      self.triggerNegative(loginErr);
                      //self.$refs.login_btn.disable = false;
                      self.login_btn_disable = false;
                  } else {
                      //console.log("JWT => " + responseDoc.getElementsByTagName('jwt_token')[0].textContent);
                      let username = responseDoc.getElementsByTagName('mcode')[0].textContent.toUpperCase();
                      
                      self.triggerPositive("ยินดีต้อนรับ : " + username);
                      store.set("APP_TOOLBAR_CLASS","display");
                      store.set("JWT_TOKEN", responseDoc.getElementsByTagName('jwt_token')[0].textContent);
                      store.set("SESSION_USERNAME", username);
                      store.set("SESSION_MCODE_LOGIN", responseDoc.getElementsByTagName('mcode')[0].textContent.toUpperCase());
                      store.set("SESSION_MCODE_NAME", responseDoc.getElementsByTagName('namet')[0].textContent);
                      store.set("SESSION_MDATE", responseDoc.getElementsByTagName('mdate')[0].textContent);

                      store.set("SESSION_UP_LEVEL", responseDoc.getElementsByTagName('up_level')[0].textContent);
                      store.set("SESSION_SP_LEVEL", responseDoc.getElementsByTagName('sp_level')[0].textContent);
                      store.set("SESSION_AGENT", responseDoc.getElementsByTagName('agent')[0].textContent);
                      store.set("SESSION_SCODE", responseDoc.getElementsByTagName('scode')[0].textContent);
                      store.set("SESSION_SNAME", responseDoc.getElementsByTagName('sname')[0].textContent);

                      store.set("SESSION_SESSIONID", responseDoc.getElementsByTagName('sessionid')[0].textContent);
                      //self.$refs.login_btn.disable = false;
                      //self.login_btn_disable = false;
                      self.saveState();
                      if (self.officeControlUser.length > 0) {
                        //alert("push UplineTree");
                        self.$router.push({ name:"UplineTree"});
                      } else {
                        //alert("push Main");
                        self.$router.push({ name:"Main"});
                      }
                  }
              })
              .catch(function (error) {
                  console.log("Error :" + error);
                  self.loading = false;
              });
       }
       
       
    },
    init () {
          let self = this;
          self.axios({
            method: 'post',
            timeout: 1000,
            url: self.url_api + 'MemberClient/GetLastUpdatedData.aspx'
          })
          .then(function (response) {
              //console.log("result :" + response.data);
              let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
              let shutdown_value = responseDoc.getElementsByTagName('shutdown_value')[0].textContent;
              let shutdown_message = responseDoc.getElementsByTagName('msg')[0].textContent;
              let current_date = responseDoc.getElementsByTagName('CURRENT_DATE')[0].textContent;

              self.date_text = "วันที่ : " + current_date;

              if (shutdown_value == "1") {
                self.status_text = "Offline";
                self.status_color = "red-7";
                self.triggerNegative(shutdown_message);
                self.login_panel_class = "hidden";
              } else {
                self.status_text = "Online";
                self.status_color = "light-green-7"
                self.login_panel_class = "";
                store.set("SESSION_DATE", current_date);
                store.set("SESSION_EXPDATE", responseDoc.getElementsByTagName('CURRENT_EXPDATE')[0].textContent);
                //store.set("APP_SHUTDOWN_VALUE", shutdown_value);
                store.set("APP_SHUTDOWN_MSG", shutdown_message);
                self.officeControl();
              }
              setTimeout(() => {
                self.$q.loading.hide();
              }, 2000);
          })
          .catch(function (error) {
              console.log("init Error :" + error);
              self.$q.loading.hide();
              self.status_text = "Offline";
              self.status_color = "red-7";
          });  
    },
    /*
    fetchBranch: function () {
      let self = this;
      self.axios({
      method: "post",
        url: self.url_api + "Data/Branch.ListAll.JSON.aspx"
      })
      .then(function (response) {
        console.log("branch result :" + response.data[0].label);
        self.branchList = response.data;
        self.branch_selected = response.data[0];
      })
      .catch(function (error) {
        console.log("Error :" + error);
      });
    },
    */
    officeControl: function () {
      let url_path = window.location.href;
      let start_param = url_path.indexOf("?jwt=");
      let jwt = url_path.substring(start_param,url_path.length).replace("?jwt=","");
      try {
        //alert("jwt : " + jwt);
        if (jwt.length > 0) {
            //alert("process");
            var self = this;
            self.$q.loading.show();
            const params = new URLSearchParams();
            params.append('jwt', jwt);
            self.axios({
              method: 'post',
              timeout: 0, // Let's say you want to wait at least unlimit mins,
              url: self.url_api + 'MemberClient/ClientParam.JSON.aspx',
              data: params
            })
            .then(function (response) {
                try {
                    let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                    let office_control_user = responseDoc.getElementsByTagName('user')[0].textContent;
                    let mcode = responseDoc.getElementsByTagName('mcode')[0].textContent;
                    let member_username = responseDoc.getElementsByTagName('member_username')[0].textContent;
                    let password = responseDoc.getElementsByTagName('password')[0].textContent;
                    self.mcode = mcode;
                    self.username = member_username;
                    self.password = password;

                    store.set("OFFICE_CONTROL_USER", office_control_user);

                    self.$q.loading.hide();
                    self.loginProcess();
                } catch(err) {
                  //console.log(err.message);
                  self.$q.loading.hide();
                }
            })
            .catch(function (error) {
                //console.log("Error :" + error + ' round : ' + self.selected.value);
                console.log("Error :" + error);
                self.$q.loading.hide();
            });
        }
      }
      catch(err) {
        console.log(err.message);
      }
    },
    saveState () {
      const serializedState = JSON.stringify(this.$store.state)
      sessionStorage.setItem('appKHWMemberState', serializedState)
    },
  },  
  mounted: function () {
    store.set("APP_TOOLBAR_CLASS","hidden");
    sessionStorage.removeItem("appKHWMemberState");
    //this.$parent.$refs.AppToolbar.setAttribute("style", "display:none;");
    //let self = this;
    //self.$parent.test_label = "pphongraksa@hotmail.com";
    //console.log("parent label:" + this.$parent.test_label);
  },
  computed: {
      url_api: get('URL_API_PATH'),
      appLangUI: get('APP_LANG_UI'),
      officeControlUser: get('OFFICE_CONTROL_USER'),
      appShutdownValue: get('APP_SHUTDOWN_VALUE'),
  },
  created () {
    this.$q.loading.show();
    this.init();
  },
  watch: {
    appShutdownValue: function () {
        if (this.appShutdownValue == "0") {
            this.status_text = "Online";
            this.status_color = "light-green-7";
            this.readonly = false;
            this.login_panel_class = "";
        } else {
            if (this.appShutdownValue == "1") {
              this.status_text = "Offline";
              this.status_color = "red-7";
              this.readonly = true;
              //alert("hidden");
              this.login_panel_class = "hidden";
            }  
        }
    }
  }
}
